import paymentMethodConfigRequest from 'request/payment-method/config'
import updateSeletedCardRequest from 'request/payment-method/card-update'

const state = {
  method: null,
  methodParams: {},
  selectedCard: null,
  mapPayRewards: 0,
  cards: [],
  urlQueryParams: null,
  installment: '1',
  zerocardFeeRateDic: {},
  retryGooglePay: 0,
  retryApplePay: 0,
  isPayMethodSupported: {}
}

let init = false

const cardImageDic = {
  'visa': 'creditcard/card_visa.png',
  'masterCard': 'creditcard/card_mastercard.png',
  'jcb': 'creditcard/card_jcb.png',
  'unknown': 'creditcard/card_unknown.png',
  'amex': 'creditcard/card_amex.png'
}
const installmentDic = {
  '1': 'one',
  '3': 'three',
  '6': 'six',
  '9': 'nine',
  '12': 'twelve'
}
const supportedPaymentDic = {
  'buyDeposit': {},
  'prepayBooking': { applePay: true, googlePay: true, bindedCard: true, linePay: true, plusPay: true },
  'checkoutBooking': { applePay: true, googlePay: true, bindedCard: true, linePay: true, plusPay: true, cash: true },
  'buyCourse': { applePay: true, googlePay: true, bindedCard: true, payoutPay: true, linePay: true, plusPay: true },
  'buyVendorProduct': { payoutPay: true }
}

export default function usePaymentMethod(type) {
  // eslint-disable-next-line no-unused-vars
  const { data, mutate } = useSWR('/hooks/payment', uuid)
  const { isMember, user } = useCurrent()

  useEffect(() => {
    if (!user) {
      init = false
      return
    }
    if (init) return
    init = true
    refreshConfig()
  }, [user])

  useEffect(() => {
    if (type === 'buyDeposit') {
      state.method = 'atm'
      mutate()
      return
    }
    checkGoogleReady()
    checkApplePayReady()
    checkMethodSupported(type)
    if (!isMember) return
    if (!['buyVendorProduct', 'buyCourse'].includes(type)) return
    state.method = 'payout'
    mutate()
  }, [type])

  const refreshConfig = async() => {
    const { cards, mapPayCard, mapPayRewards, zeroCardFeeRateDic } = await paymentMethodConfigRequest()
    state.mapPayRewards = mapPayRewards
    state.zerocardFeeRateDic = zeroCardFeeRateDic
    state.cards = cards.map(card => {
      card.$imgUrl = cdn(cardImageDic[card.cardType])
      if (parseInt(card.id) === mapPayCard) state.selectedCard = card
      return card
    })
    if (!state.method) state.method = defaultMethodResolver()
    if (state.method !== 'bindedCard') state.selectedCard = null
    mutate()
  }

  const checkMethodSupported = type => {
    const isSupportResolver = () => {
      const isPayMethodSupported = supportedPaymentDic[type]
      if (!isPayMethodSupported) return {}
      const membership = user?.stylist?.stylistMembership
      if (isPayMethodSupported.payoutPay) isPayMethodSupported.payoutPay = !!membership
      return isPayMethodSupported
    }

    state.isPayMethodSupported = isSupportResolver()
    mutate()
  }

  const defaultMethodResolver = () => {
    if (state.selectedCard) return 'bindedCard'
    return null
  }

  const paymentMethodChange = async(method, card) => {
    await selectCard(card)
    state.method = method
    state.selectedCard = card
    state.methodParams = { ...state.methodParams, ...defaultMethodParamsResolver() }
    if (state.onPaymentMethodChange) state.onPaymentMethodChange()
    mutate()
  }

  const selectCard = async card => {
    if (!card) return

    await updateSeletedCardRequest(card.id)
    await refreshConfig()
  }

  const defaultMethodParamsResolver = () => {
    if (state.method === 'zerocard') return { installment: installmentDic[state.installment] }
    return {}
  }

  const setInstallment = installment => {
    state.installment = installment
    state.methodParams = {
      ...state.methodParams,
      installment: installmentDic[installment]
    }
    mutate()
  }

  const feeResolver = originAmount => {
    if (state.method !== 'zerocard') return 0

    const feeRate = state.zerocardFeeRateDic[state.installment]
    const zerocardPlanPrice = zerocardPlanPriceResolver(originAmount, state.installment, feeRate)
    return zerocardPlanPrice * state.installment - originAmount
  }

  const zerocardPlansResolver = amount => Object.entries(state.zerocardFeeRateDic).map(([installment, feeRate]) => {
    const planPrice = zerocardPlanPriceResolver(amount, installment, feeRate)
    const total = planPrice * installment
    return {
      key: installment,
      value: { planPrice, installment, total }
    }
  })

  const setUrlQueryParams = urlQueryParams => {
    state.urlQueryParams = urlQueryParams
    state.methodParams = { urlQueryParams: JSON.stringify(urlQueryParams) }
  }

  const setType = type => {
    checkMethodSupported(type)
  }

  return {
    ...state,
    refreshConfig,
    paymentMethodChange,
    feeResolver,
    setInstallment,
    setUrlQueryParams,
    onZerocardInstallmentSelect: setInstallment,
    zerocardPlansResolver,
    zerocardPlanPriceResolver,
    setType
  }
}

const checkApplePayReady = () => {
  try {
    if (!TPDirect.paymentRequestApi.checkAvailability()) return
    TPDirect.paymentRequestApi.setupApplePay({
      merchantIdentifier: process.env.NEXT_PUBLIC_TAPPAY_APPLE_PAY_MERCHANT_ID,
      countryCode: 'TW'
    })

    const testPaymentRequest = {
      supportedNetworks: ['AMEX', 'JCB', 'MASTERCARD', 'VISA'],
      supportedMethods: ['apple_pay'],
      displayItems: [{
        amount: {
          currency: 'TWD',
          value: '1'
        }
      }],
      total: {
        amount: {
          currency: 'TWD',
          value: '1'
        }
      }
    }
    TPDirect.paymentRequestApi.setupPaymentRequest(testPaymentRequest, async result => {
      if (!result.browserSupportPaymentRequest) return
      state.isApplePayReady = true
    })
  } catch {
    if (state.retryApplePay >= 3) return
    state.retryApplePay += 1
    setTimeout(checkApplePayReady, 1000)
  }
}

const checkGoogleReady = () => {
  try {
    const testRequest = {
      allowedNetworks: ['AMEX', 'JCB', 'MASTERCARD', 'VISA'],
      price: '1',
      currency: 'TWD'
    }

    TPDirect.googlePay.setupPaymentRequest(testRequest, (err, result) => {
      state.isGooglePayReady = result.canUseGooglePay
    })
  } catch {
    if (state.retryGooglePay >= 3) return
    state.retryGooglePay += 1
    setTimeout(checkGoogleReady, 1000)
  }
}

const zerocardPlanPriceResolver = (amount, installment, feeRate) => Math.ceil(amount / (1 - feeRate) / installment)
